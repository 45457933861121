var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
// ----------------------------------------------------------------------
var StyledRoot = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 2.5),
        borderRadius: Number(theme.shape.borderRadius) * 1.5,
        backgroundColor: alpha(theme.palette.grey[500], 0.12),
    });
});
// ----------------------------------------------------------------------
export default function NavAccount() {
    var user = useAuthContext().user;
    return (_jsxs(StyledRoot, { children: [_jsx(CustomAvatar, { src: user === null || user === void 0 ? void 0 : user.avatar, alt: user === null || user === void 0 ? void 0 : user.name, name: user === null || user === void 0 ? void 0 : user.name }), _jsxs(Box, __assign({ sx: { ml: 2, minWidth: 0 } }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", noWrap: true }, { children: user === null || user === void 0 ? void 0 : user.name })), _jsx(Typography, __assign({ variant: "body2", noWrap: true, sx: { color: 'text.secondary' } }, { children: user === null || user === void 0 ? void 0 : user.role }))] }))] }));
}
